/* You can add global styles to this file, and also import other style files */
//Tables
tr td{
    padding:5px 16px!important;
}

th{
    font-size: 14px!important;
    font-family: 'Roboto', sans-serif;
    color: #445565!important;
    font-weight: 500;
}

.ant-table-footer{
    background:#ffffff!important;
}


.ant-card{
    border-radius: 10px !important;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
}

.ant-card-body {
    padding: 12px 10px !important;
}

.auth-container{
    max-width:520px;
    margin:calc(100vh * 0.05) auto;
    border-radius: 10px;
    box-shadow: 0px 1px 3px rgba(0,0,0,0.2);
    background: rgb(255 255 255 / 95%);
}

.auth-container-body{
    padding: 20px 40px;
    text-align: center;
}

.recaptcha-container{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.ant-form-item-explain-error {
    font-size: 10px!important;
}

.ant-switch:not(.ant-switch-checked){
    background-color: #FF8C2F;
}


.ant-skeleton-element .ant-skeleton-image{
    background-color: white!important;
}
.menu-item-center i{
    padding-top: 12px !important;
    color: #fff;
}
.menu-item-center span{
    display: initial;
    position: relative;
    top: -3px;
    color: #fff;
}
.ant-menu-vertical  .menu-item-center i{
    padding-top: 4.5px !important;
    color: #fff;
}
.sub-menu-item .anticon{
    color: #fff;
    margin-top: -4px;
}
.sub-menu-item{
    color: #fff;
}
.ant-menu-item a{
    color: #fff !important;
}


/*Fonts*/
.title{
    text-align: center;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 500;
    font-size: 20px;
    color:#515151;
    margin-bottom: 0px;
}

h2{
    font-size: 18px;
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: 500;
    color: #445565;
}

h3{
    font-size: 18px;
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #445565;
}
  

/*Buttons*/

.redirect{
    cursor: pointer;
}

.ant-btn:not(.ant-btn-circle){
    border-radius: 5px !important;
}

.ant-btn-primary[disabled]{
    opacity: 0.6!important;
    color: #fff!important;
}

.ant-modal-header .ant-btn-primary{
    background:#FF8C2F;
    border-color:#FF8C2F;
}

.ant-modal-header .ant-btn-primary:hover{
    background:#FF8C2F;
    border-color:#FF8C2F;
}

.extra-lg{
    width: 70px!important;
    height: 70px!important;
    font-size: 12px;
}



/*Tags*/
.ant-tag-success {
    color: #ffffff;
    background: #00B99B;
    border-color: #00B99B;
    border-radius: 15px;
    margin-right: 0px;
}

.ant-tag-warning {
    color: #ffffff;
    background: #F8A14A;
    border-color: #F8A14A;
    border-radius: 15px;
    margin-right: 0px;
}

.ant-tag-error {
    color: #ffffff;
    background: #FE6668;
    border-color: #FE6668;
    border-radius: 15px;
    margin-right: 0px;
}

/*ICONS*/
.anticon-eye{
    color: #008AB9;
    font-size:20px;
    cursor: pointer;
}

.anticon-info-circle{
    color:#008AB9;
    font-size:12px;
}

/*Aux*/
.ant-collapse-content > .ant-collapse-content-box {
    padding: 10px!important;
}

.horizontal-space{
    margin-right: 5px;
}

.row-dc{
    margin-top: 15px;
}

.ant-descriptions-title{
    font-weight: normal!important;
}

.ant-descriptions-item-label{
    font-weight: 500!important;
}

/*Layout*/
.ant-layout {
    background: #f5f5f5!important;
}

/*Empty States*/
.ant-empty-description{
    font-family: 'Roboto', sans-serif;
    font-size:16px;
    color: #999999;
}

.ant-empty-image{
    height: 80px !important;
}

.more-size .ant-empty-image{
    margin-top:10px;
    height: 130px !important;
}
.more-size-2 .ant-empty-image{
    height: 50px !important;
}

.more-size-2 .ant-empty-description{
    font-size: 14px!important;
    white-space: pre-wrap;
}

.ant-table .ant-table-expanded-row-fixed{
    width: 100% !important;
}
.ant-tabs{
    padding: 5px !important;
}
.ant-menu.ant-menu-dark{
    margin-top: -4px !important;
}

@media(max-width:770px) {

    .pspace{
        margin-top: 15px!important;
    }

    h2{
        font-size: 14px;
    }
    
    h3{
        font-size: 14px;
    }

    .ant-empty-description{
        font-size:14px;
    }

    .ant-space-item b{
        font-weight: 500;
    }

    .row-dc{
        margin-top: 0px;
    }


    .ant-card-head {
        background: #F9F9F9;
    }

    .ant-card-head-title {
        padding: 12px 0;
    }

    .auth-container{
        margin:50px 20px;
    }

    .auth-container-body{
        padding: 20px 20px;
        text-align: center;
    }
    .divemotor-style nz-content{
        padding-left: 0px !important;
    }
    .divemotor-style nz-sider{
        max-width: initial !important;
        min-width: initial !important;
        width: 100% !important;
    }
    .divemotor-style .ant-tabs-tab{
        padding: 5px 4px !important;
        font-size: 15px !important;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap{
        justify-content: flex-start !important;
        margin-top: 5px !important;
    }
    .text-image-refe{
        font-size: 8px!important;;
    }
    .ant-menu .anticon svg{
        font-size: 16px !important;
    }

    .ant-layout-has-sider nz-content{
        padding-left: 0px !important;
    }
    .header-trigger{
        padding: 20px 21px !important;
    }
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background: #0066cc;
}
.ant-collapse-item > .ant-collapse-content{
    border-radius: 0 0 10px 10px !important;
}
.ant-layout-header{
    z-index: 99999 !important;
}
.ant-message{
    top: 65px !important;
}
.ant-menu .anticon svg{
    font-size: 20px;
}

.inner-content{
    padding-left: 84px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header{
    border-radius: 10px !important;
}
.ant-collapse-item > .ant-collapse-content{
    margin-top: 0px !important;
    position: relative !important;
    z-index: 999 ;
}

.ant-layout-has-sider nz-content{
    padding-left: 65px;
}  

/*Divemotor Styles*/
.divemotor-style {
   /*Menu*/
   .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: #0066cc !important;
    
    }
    .redirect{
        color:#008AB9!important;
    }

    .ant-layout-header{
        background: #000000!important;     
    }

    .ant-btn-primary{
        background:#0066cc;
        border-color:#0066cc;        
    }

    .ant-btn-primary:hover{
        background: #2f80ed;
        border-color:#2f80ed;
    }

    .app-header {
        position: fixed !important;
        width: 100%;
        height: 64px;
        padding: 0;
        background: #000;
        box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);        
    }
    .ant-layout-sider{
        -webkit-backdrop-filter: blur(25px);
        backdrop-filter: blur(25px);
        background: rgba(0, 0, 0, 0.6);
        transform: matrix(1, 0, 0, 1, 0, 0);
        opacity: 1;
    } 

    .sidebar-logo {
        background: rgba(29, 29, 29, 0) !important;
    }

    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
        background: #1d1d1d00;
    }

    .ant-menu.ant-menu-dark{
        background: #1d1d1d00;
    }  
    
 
    .ant-switch:not(.ant-switch-checked){
        background: #e6700c !important;
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon{
        background: #0066CC;
        display: flex;
        width: 46px ;
        height: 46px;
        border-radius: 100%;
        padding: 10px;
        color: #fff;
    }
    .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon{
        background: #E6750C;
        display: flex;
        width: 46px ;
        height: 46px;
        border-radius: 100%;
        padding: 10px;
        color: #fff;
    }
    .ant-steps-item-wait .ant-steps-item-icon{
        background: #E0E0E0;
        display: flex;
        width: 43px ;
        height: 43px;
        justify-content: center;
        align-items: center;
        color: #333333;
        font-weight: 800;
        font-size: 17.4504px;
        font-family: 'Roboto', sans-serif;
    }  
    
   .ant-tabs-tab{
    padding: 5px 29px ;
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #303030;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
        color: #0066CC;
    }
    .ant-layout{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #0066CC !important;
    }
    .ant-tabs-nav-wrap{
        border-bottom: 1px solid #f4f7fd !important;
    }
    .ant-menu-item i {
        padding-top: 4.5px;
    }
    .ant-collapse{
        background-color: #fafafa00 !important;
    }
    .menu-sidebar{
        position: fixed !important;
        margin-top: 64px;
    }
}


/*Andesmotor Styles*/
.andesmotor-style {
    font-family: 'Roboto', sans-serif !important;

    .ant-layout-header{
        background: #002e5b!important;        
    }

    .ant-btn-primary, .ant-btn-primary:hover{
        background: #4796d0;
        border-color: #4796d0;
    }
    .ant-switch:not(.ant-switch-checked){
        background-color: #ff8c2f !important;
    }
    .ant-switch{
        background-image: none !important;
    }
    h2,h3{
        font-family: 'Roboto', sans-serif;
    }
    p, .ant-table-thead>tr>th{
        font-family: 'Roboto', sans-serif;
    }    
    .ant-layout{
        background: #F2F9FD!important;
    }
    .icon-status-in-progress, .status-in-progress{
        background: #E6750C !important;
    }
    .row-content-description .icon-question-status{
        color:#E6750C !important;       
    }

    .status-wait{
        border: 1px solid#a1a1a1 !important;
    }
    nz-sider{
        position: fixed !important;
        margin-top: 64px;
    }
    .app-header{
        position: fixed !important;
        width: 100%;
        height: 64px;
        background: #002e5b!important;     
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap{
        border-bottom: 2px solid #f2f9fd !important;
    }
    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected{
        background: #4796d0 !important;
    }
    .ant-tabs-tab{
        padding: 5px 29px;
        font-family: "Roboto Condensed", sans-serif;       
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
    }
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title{
    padding: 0 calc(44% - 7px ) !important;
}
